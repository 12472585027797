<template>
  <div class="position-relative">
    <div
      v-if="isLoadingData"
      class=" d-flex justify-content-center align-items-center forecast-spinner"
    >
      <Spinner />
    </div>
    <FourTabsLayout>
      <template #page-header>
        <div class="container">
          <SelectInput
            v-model="brand"
            class="brand-name-selector"
            :errors="v$.brand.$errors"
            :options="brandNameOptions"
            description="Choose brand"
            label="Brand name"
            @blur="v$.brand.$touch"
            @change="fetchOnChangeBrand"
          />
        </div>
      </template>
      <template #tab-1-header>
        Forecasts
      </template>
      <template #tab-1-content>
        <div
          v-if="forecastData.length"
          class="card-body p-0 w-100 d-flex justify-content-center"
          style="height: 50em;"
        >
          <ag-grid-vue
            :columnDefs="columnDefsTab1"
            :defaultColDef="defaultColDefTab1"
            :pagination="true"
            :paginationAutoPageSize="true"
            :rowData="forecastData"
            class="ag-theme-alpine h-100 w-100"
            sideBar="filters"
          />
        </div>
      </template>
      <template #tab-2-header>
        Upload new forecast
      </template>
      <template #tab-2-content>
        <div
          v-if="brand"
          class="container mt-5"
        >
          <div class="row d-flex justify-content-center">
            <div class="col col-6">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  Forecast upload
                </div>
                <div class="card-body w-100 p-5 position-relative">
                  <div class="upload-container">
                    <h4>Upload forecast file here</h4>
                    <DatePicker
                      v-model="selectedDate"
                      :format="dateFormatter"
                      :errors="v$.selectedDate.$errors"
                      description="Choose first date of forecast for uploaded file"
                      label="Forecast first date"
                      @blur="v$.selectedDate.$touch"
                    />
                    <div class="mt-2">
                      <SelectInput
                        v-model="selectedLocation"
                        :options="locationsOptions"
                        :errors="v$.selectedLocation.$errors"
                        description="Choose location for the uploaded forecast"
                        label="Forecast location"
                        @blur="v$.selectedLocation.$touch"
                      />
                    </div>
                    <form id="upload-invoice-form">
                      <FileUploadInput
                        ref="file-upload-input"
                        description="Put your file here"
                        :acceptedExtensions="acceptedExtensions"
                        :errors="inputFileErrors"
                        @blur="validateInputFile"
                      >
                        <template #label-html>
                          CSV (<span class="text-info">.csv</span>) file:
                        </template>
                      </FileUploadInput>
                    </form>
                    <CheckboxInput
                      v-if="isApplyAllDatesSectionVisible"
                      v-model="isApplyAllDates"
                      description="This is extra option to upload and apply forecast data for all dates from uploaded file"
                      label="Apply all dates from file"
                    />
                  </div>
                </div>
                <div
                  v-if="uploadingInProgress"
                  class="position-absolute d-flex align-items-center w-100 justify-content-center h-100 spinner-background"
                >
                  <Spinner />
                </div>
                <div class="card-footer">
                  <div class="float-end">
                    <button
                      class="btn btn-primary"
                      :disabled="isUploadButtonDisabled"
                      @click="uploadFile"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="serverValidationErrors.length > 0"
                class="card mt-5"
              >
                <div class="card-header d-flex justify-content-between bg-danger">
                  File validation errors
                </div>
                <div class="card-body w-100 p-5 bg-opacity-10 bg-danger">
                  <p
                    v-for="(error, index) in serverValidationErrors"
                    :key="index"
                  >
                    <span class="font-weight-bold">{{ index + 1 }}</span>.
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #tab-3-header>
        Alert client recipients
      </template>
      <template #tab-3-content>
        <div
          v-if="brand"
          class="container w-100"
        >
          <div class="container d-flex justify-content-center py-2 ">
            <button
              v-if="isAddRecipientBtnVisible"
              class="btn btn-primary"
              @click="addNewClickHandler()"
            >
              Add email
            </button>
          </div>
          <div
            v-if="forecastDecisionRecipients.length"
            class="card-body p-0 w-100 d-flex justify-content-center"
            style="height: 50em;"
          >
            <ag-grid-vue
              :columnDefs="columnDefsTab3"
              :defaultColDef="defaultColDefTab3"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="forecastDecisionRecipients"
              class="ag-theme-alpine h-100 w-100"
              sideBar="filters"
            />
          </div>
        </div>
      </template>
      <template #tab-4-header>
        Rules
      </template>
      <template #tab-4-content>
        <div class="container mt-5">
          <div class="row d-flex justify-content-center">
            <div class="col col-6">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  Rules
                  <button
                    v-if="isEditRuleBtnVisible"
                    class="btn btn-sm btn-primary"
                    @click="editRulesClickHandler()"
                  >
                    Edit
                  </button>
                </div>
                <div class="card-body w-100 p-5">
                  <div class="profile-container">
                    <TextInput
                      v-model="rules.ecomLowerBound"
                      class="p-2"
                      label="eCommerce lower bound"
                      readonly
                    />
                    <TextInput
                      v-model="rules.ecomUpperBound"
                      class="p-2"
                      label="eCommerce upper bound"
                      readonly
                    />
                    <TextInput
                      v-model="rules.ltmLaserLowerBound"
                      class="p-2"
                      label="LTM Laser lower bound"
                      readonly
                    />
                    <TextInput
                      v-model="rules.ltmLaserUpperBound"
                      class="p-2"
                      label="LTM Laser upper bound"
                      readonly
                    />
                    <TextInput
                      v-model="rules.ltmEmbLowerBound"
                      class="p-2"
                      label="LTM Embroidery lower bound"
                      readonly
                    />
                    <TextInput
                      v-model="rules.ltmEmbUpperBound"
                      class="p-2"
                      label="LTM Embroidery upper bound"
                      readonly
                    />
                    <TextInput
                      v-model="rules.whWarnBefore"
                      class="p-2"
                      label="Wholesale warning before start"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </FourTabsLayout>
  </div>
  <ConfirmationModal
    ref="confirmation-approve-modal"
    :onCancelHandler="cancelHandler"
    :onConfirmHandler="approveHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          Forecast status has been changed to approved.
        </h6>
      </div>
    </template>
    <template #cancel-button-text>
      Cancel
    </template>
    <template #confirm-button-text>
      Approve
    </template>
  </ConfirmationModal>
  <ConfirmationModal
    ref="confirmation-reject-modal"
    :onCancelHandler="cancelHandler"
    :onConfirmHandler="rejectHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          Forecast status has been changed to rejected.
        </h6>
      </div>
    </template>
    <template #cancel-button-text>
      Cancel
    </template>
    <template #confirm-button-text>
      Reject
    </template>
  </ConfirmationModal>
  <ForecastEditRecipientModal
    id="forecast-modal"
    ref="forecastModal"
    :viewType="modalViewType"
  />
  <ForecastViewModal
    ref="forecastViewModal"
    :viewType="modalViewType"
  />
  <ForecastEditRuleModal
    id="forecast-rule-modal"
    ref="forecastRuleModal"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SelectInput from '@/components/common/SelectInput';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import FourTabsLayout from '../Layouts/FourTabsLayout';
import { AgGridVue } from 'ag-grid-vue3';
import Spinner from '@/components/common/Spinner';
import FileUploadInput from '@/components/common/FileUploadInput';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import ForecastEditRecipientModal from '@/components/Forecast/ForecastEditRecipientModal';
import ForecastViewModal from '@/components/Forecast/ForecastViewModal';
import { dateFormatter, modalViewTypes, userPrivileges } from '@/components/constants';
import TextInput from '../common/TextInput';
import ForecastEditRuleModal from './ForecastEditRuleModal';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';
import DatePicker from '@/components/common/DatePicker.vue';
import { hasPrivilege } from '@/service/userAccess';
import CheckboxInput from '@/components/common/CheckboxInput.vue';

const MAX_FILE_SIZE = 120 * 1024 * 1024; // 120 Megabytes

export default {
  components: {
    CheckboxInput,
    FourTabsLayout,
    ConfirmationModal,
    ForecastViewModal,
    ForecastEditRecipientModal,
    ForecastEditRuleModal,
    SelectInput,
    FileUploadInput,
    Spinner,
    AgGridVue,
    TextInput,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
    DatePicker
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isLoadingData: false,
      modalViewType: null,
      modalEditRule: null,
      processedForecastId: null,
      processedRecipientId: null,
      confirmationApproveModal: null,
      forecastEditRecipientModal: null,
      confirmationRejectModal: null,
      brand: this.$route.params.brandId,
      uploadingInProgress: false,
      inputFileErrors: [],
      inputFormErrors: [],
      serverValidationErrors: [],
      selectedDate: null,
      isForecastDownloading: false,
      selectedLocation: null,
      isApplyAllDates: false
    };
  },
  validations() {
    return {
      brand: { required: required },
      selectedDate: { required: required },
      selectedLocation: { required: required }
    };
  },
  computed: {
    ...mapGetters({
      companies: 'forecast/companyItems',
      forecastData: 'forecast/forecasts',
      forecastDecisionRecipients: 'forecast/forecastRecipients',
      rules: 'forecast/forecastRules',
      locations: 'brandManagement/brandLocations'
    }),
    isApplyAllDatesSectionVisible() {
      return hasPrivilege(userPrivileges.applyAllDatesInUploadForecast);
    },
    locationsOptions() {
      return this.locations.map((item) => ({ key: item, value: item }));
    },
    acceptedExtensions() {
      return '.csv';
    },
    isUploadButtonDisabled() {
      return !hasPrivilege(userPrivileges.uploadAdminForecast) ||
        this.inputFileErrors.length ||
        !this?.$refs['file-upload-input']?.getFile() ||
        !this.isInputUploadFormValid;
    },
    isAddRecipientBtnVisible() {
      return hasPrivilege(userPrivileges.createForecastRecipient);
    },
    isEditRuleBtnVisible() {
      return hasPrivilege(userPrivileges.updateForecastRule);
    },
    defaultColDefTab1() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefsTab1() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Open',
            buttonVariant: 'btn-success',
            clicked: (forecastId) => {
              this.openForecastClickHandler(forecastId);
            }
          },
          width: 90
        },
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Download',
            buttonVariant: 'btn-primary',
            clicked: (forecastId) => {
              this.downloadForecastClickHandler(forecastId);
            },
            isDisabled: () => {
              return this.isDownloadBtnDisabled;
            },
            visibilityCheck: (forecast) => {
              return this.downloadForecastBtnVisible(forecast);
            }
          },
          width: 120
        },
        {
          headerName: 'ID',
          field: 'id',
          width: 100
        },
        {
          headerName: 'Location',
          field: 'location',
          width: 100
        },
        {
          headerName: 'Upload date',
          field: 'uploadedDate',
          width: 150,
          sortable: true,
          valueFormatter: ({ value }) => this.formatDate(value),
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 150,
          sortable: true
        },
        {
          headerName: 'Approval date',
          field: 'approvalDate',
          width: 150,
          sortable: true,
          valueFormatter: ({ value }) => this.formatDate(value),
        },
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Approve',
            buttonVariant: 'btn-success',
            visibilityCheck: (rowData) => {
              return rowData.status === 'PENDING' &&
                hasPrivilege(userPrivileges.updateForecastStatus);
            },
            clicked: (id) => {
              this.approveClickHandler(id);
            },
          },
          width: 120
        },
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Reject',
            buttonVariant: 'btn-danger',
            visibilityCheck: (rowData) => {
              return rowData.status === 'PENDING' &&
                hasPrivilege(userPrivileges.updateForecastStatus);
            },
            clicked: (id) => {
              this.rejectClickHandler(id);
            },
          },
          width: 120
        },
        {
          headerName: 'Uploaded by',
          field: 'uploadedBy',
          width: 200,
          sortable: true
        },
        {
          headerName: 'Approved by',
          field: 'approvedBy',
          width: 200,
          sortable: true
        }
      ];
    },
    defaultColDefTab3() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefsTab3() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.editClickHandler(field);
            },
            visibilityCheck: () => {
              return hasPrivilege(userPrivileges.createForecastRecipient);
            },
          },
          width: 80
        },
        {
          headerName: 'email',
          field: 'email',
          width: 550
        },
        {
          field: 'receiveForecastDecision',
          headerName: 'Receive Forecast decisions',
          headerTooltip: 'Receive Forecast decisions',
          cellRenderer: booleanIconCellRenderer(),
          width: 150,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'receiveMismatchAlert',
          headerName: 'Receive Mismatch alerts',
          headerTooltip: 'Receive Mismatch alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 150,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'receiveLackOfForecastAlert',
          headerName: 'Receive Lack of Forecast alerts',
          headerTooltip: 'Receive Lack of Forecast alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 150,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'receiveNewForecastAlert',
          headerName: 'Receive New Forecast alerts',
          headerTooltip: 'Receive New Forecast alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 150,
          wrapText: true,
          autoHeight: true
        }
      ];
    },
    brandNameOptions() {
      return _.chain(_.values(this.companies))
        .map((item) => ({ key: item.brandId, value: item.name }))
        .orderBy(['value'], ['asc'])
        .value();
    },
    getBrandNameById(){
      return this.companies[this.brand].name;
    },
    isDownloadBtnDisabled() {
      return this.isForecastDownloading;
    },
    isInputUploadFormValid() {
      return this.selectedDate &&
        this.selectedLocation;
    }
  },
  async mounted() {
    await this.fetchCompanyItems();
    await this.fetchTabs();
    this.confirmationApproveModal = this.$refs['confirmation-approve-modal'].confirmationModal;
    this.confirmationRejectModal = this.$refs['confirmation-reject-modal'].confirmationModal;
    this.confirmationApproveModal.hide();
    this.confirmationRejectModal.hide();
    this.preselectDate();
  },
  methods: {
    ...mapActions({
      fetchRules: 'forecast/fetchRules',
      fetchCompanyItems: 'forecast/fetchCompanyItems',
      fetchForecasts: 'forecast/fetchForecastsByBrand',
      fetchForecastDecisionRecipients: 'forecast/fetchForecastRecipients',
      uploadForecastFile: 'forecast/uploadForecastFile',
      changeForecastStatus: 'forecast/changeStatus',
      downloadForecast: 'forecast/downloadForecastFile',
      fetchBrandLocations: 'brandManagement/fetchBrandLocations'
    }),
    downloadForecastBtnVisible(forecast) {
      return hasPrivilege(userPrivileges.getForecastFile)
        && forecast?.filename
        && !this.checkIsDefaultForecast(forecast)
        && forecast?.uploadedDate;
    },
    checkIsDefaultForecast(forecast) {
      return forecast?.filename.includes('DEFAULT FORECAST');
    },
    preselectDate() {
      const now = new Date();
      const options = { timeZone: 'America/New_York' };
      const nyTimeString = now.toLocaleString('en-US', options);
      const nyTime = new Date(nyTimeString);

      if (nyTime.getDate() < 15) {
        nyTime.setMonth(nyTime.getMonth() + 1);
      } else {
        nyTime.setMonth(nyTime.getMonth() + 2);
      }
      nyTime.setDate(1);

      const year = nyTime.getFullYear();
      const month = ('0' + (nyTime.getMonth() + 1)).slice(-2);
      const day = ('0' + nyTime.getDate()).slice(-2);
      const hours = ('0' + nyTime.getHours()).slice(-2);
      const minutes = ('0' + nyTime.getMinutes()).slice(-2);
      const seconds = ('0' + nyTime.getSeconds()).slice(-2);
      const milliseconds = ('00' + nyTime.getMilliseconds()).slice(-3);

      this.selectedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    },
    editRulesClickHandler() {
      if (this.rules.id) {
        this.modalViewType = modalViewTypes.edit;
        this.$refs.forecastRuleModal.initModal(this.brand);
      } else {
        this.modalViewType = modalViewTypes.edit;
        this.$refs.forecastRuleModal.initAddModal(this.brand, this.getBrandNameById);
      }
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      }
    },
    async approveHandler() {
      await this.changeForecastStatus({
        id: this.processedForecastId,
        status: 'APPROVED'
      });
      this.processedForecastId = null;
      this.fetchForecasts(this.brand);
    },
    async rejectHandler() {
      await this.changeForecastStatus({
        id: this.processedForecastId,
        status: 'REJECTED'
      });
      this.processedForecastId = null;
      this.fetchForecasts(this.brand);
    },
    editClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.forecastModal.initModal(id);
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.forecastModal.initAddModal(this.brand, this.getBrandNameById);
    },
    openForecastClickHandler(forecastId) {
      this.$refs.forecastViewModal.initModal(forecastId);
    },
    async downloadForecastClickHandler(forecastId) {
      this.isForecastDownloading = true;
      const forecast = _.find(this.forecastData, { id: forecastId });
      const requestData = {
        fileName: forecast.filename
      };
      try {
        await this.downloadForecast(requestData);
      } catch (error) {
        this.$toast.error('Internal error due downloading forecast. Please contact administrator.');
      } finally {
        this.isForecastDownloading = false;
      }
    },
    cancelHandler() {
      this.confirmationApproveModal.hide();
      this.confirmationRejectModal.hide();
      this.processedForecastId = null;
    },
    async approveClickHandler(id) {
      this.processedForecastId = id;
      this.confirmationApproveModal.show();
    },
    async rejectClickHandler(id) {
      this.processedForecastId = id;
      this.confirmationRejectModal.show();
    },
    fetchOnChangeBrand() {
      this.$router.push(`/forecasts/${this.brand}`);
      this.fetchTabs();
    },
    async fetchTabs() {
      this.isLoadingData = true;
      await Promise.all([
        this.fetchForecasts(this.brand),
        this.fetchForecastDecisionRecipients(this.brand),
        this.fetchRules(this.brand),
        this.fetchBrandLocations(this.brand),
      ]);
      this.isLoadingData = false;
    },
    validateInputFile() {
      this.inputFileErrors = [];
      const file = this.$refs['file-upload-input'].getFile();
      if (!file) {
        this.inputFileErrors.push({
          $uid: 'file-is-required-error',
          $message: 'The file is required'
        });
        return;
      }
      const fileExtension = _.last(file.name.split('.'));
      if (this.acceptedExtensions.indexOf(fileExtension) === -1) {
        this.inputFileErrors.push({
          $uid: 'file-extension-is-not-allowed-error',
          $message: 'The file extension is not allowed'
        });
      }
      const fileSize = file.size;
      if (fileSize > MAX_FILE_SIZE) {
        this.inputFileErrors.push({
          $uid: 'file-is-too-big',
          $message: 'The file is too big. Please contact administrator for further instructions.'
        });
      }
    },
    async uploadFile() {
      this.uploadingInProgress = true;
      this.serverValidationErrors = [];
      this.validateInputFile();
      if (this.inputFileErrors.length > 0) {
        this.$toast.error('The file must be valid');
        return;
      }
      const formData = new FormData();
      formData.append('location', this.selectedLocation);
      formData.append('file', this.$refs['file-upload-input'].getFile());
      formData.append('brandId', this.brand);
      const date = this.selectedDate.split('T')[0];
      formData.append('dateFrom', date);
      formData.append('applyAllDates', this.isApplyAllDates);
      try {
        await this.uploadForecastFile(formData);
        this.$toast.success('The file uploaded successfully');
        this.$refs['file-upload-input'].reset();
        this.inputFileErrors = [];
      } catch (error) {
        const { errorDetails } = error.cause;
        this.serverValidationErrors = errorDetails;
        const errorToastMessage = 'Please fix the file.<br>';
        if (this.serverValidationErrors.length > 0) {
          this.$toast.error(errorToastMessage);
        } else {
          this.$toast.error(errorToastMessage + error);
        }
      } finally {
        this.uploadingInProgress = false;
      }
    },
    dateFormatter(date) {
      return dateFormatter(date);
    }
  }
};
</script>
<style>
.forecast-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
}
.brand-name-selector label {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}
</style>

